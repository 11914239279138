import { RayloModal, SectionMessageBase, Spacer, StyledSpan, Typography } from 'uibook';
import { useAppContext } from '../../hooks/useAppContext';
import { IAddTechModal } from './types';
import { useCloseModalOnRouterEvent } from '@/hooks/useCloseModalOnRouterEvent';
import { useFilterByPreApprovedAmount } from '../ProductFilter/hooks/useFilterByPreApprovedAmount';

const AboutYourLimitModal = ({ dataTestId, formattedAmount }: IAddTechModal) => {
  const { modalOpen, setModalOpen } = useAppContext();
  const { preApprovedFilterEnabled, filterByPreApprovedAmount } = useFilterByPreApprovedAmount();

  useCloseModalOnRouterEvent(modalOpen === 'aboutYourLimit');

  return (
    <RayloModal
      modalOpen={modalOpen === 'aboutYourLimit'}
      dataTestId={dataTestId}
      handleCloseModal={() => setModalOpen(null)}
      primaryButtonOnClick={() => setModalOpen(null)}
      primaryButtonText="OKAY"
      secondaryButtonText={preApprovedFilterEnabled ? undefined : 'FILTER BY PRE-APPROVED'}
      secondaryButtonOnClick={
        preApprovedFilterEnabled
          ? undefined
          : () => {
              filterByPreApprovedAmount('enable');
              setModalOpen(null);
            }
      }
      title="About your limit"
    >
      <Typography>
        Your available pre-approved limit is <StyledSpan boldFont>{formattedAmount}/mo</StyledSpan>
      </Typography>
      <Spacer height={12} />
      <Typography>
        This is the maximum you have been approved to spend on extra tech per month.
      </Typography>
      <Spacer height={12} />
      <SectionMessageBase
        header="Your limit is based on"
        bulletList={['How long you have been a customer', 'Your payment history']}
      />
      <Spacer height={12} />
      <Typography variant="body2" className="text-charcoal-400">
        Your available pre-approved limit can fluctuate and is subject to checks.
      </Typography>
    </RayloModal>
  );
};

export default AboutYourLimitModal;
