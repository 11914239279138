import { StyledPreApprovedHeaderBannerContent } from '../AddTech/styles';
import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';
import { cn, palette } from 'uibook';

export const HeaderBanner = ({
  children,
  onClick,
  className,
}: {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={cn('w-full bg-white px-6 py-4', className)}>
    <StyledPreApprovedHeaderBannerContent onClick={onClick}>
      {children}
      <Icon name="Info" fill={palette.charcoal[400]} size={px2Rem(16)} />
    </StyledPreApprovedHeaderBannerContent>
  </div>
);
