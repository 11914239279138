import Logo from './svgs/rayloLogo.svg';
import Twitter from './svgs/twitter.svg';
import Instagram from './svgs/instagram.svg';
import Facebook from './svgs/facebook.svg';
import Warranty from './svgs/icon/streamline/warranty.svg';
import PhoneSelfie from './svgs/icon/streamline/phone-selfie-front.svg';
import Calendar from './svgs/icon/streamline/calendar.svg';
import CertifiedPhoneShield from './svgs/certifiedphoneshield.svg';
import CertifiedWarranty from './svgs/certifiedwarranty.svg';
import PhoneDoubleCamera from './svgs/phone-double-camera.svg';
import CustomerSupport36px from './svgs/customer-support-human-36px.svg';
import PhoneDoubleCamera36px from './svgs/phone-double-camera-36.svg';
import EcologyGlobe36px from './svgs/ecology-globe-nature-36px.svg';
import CertifiedPhoneShield36px from './svgs/shield-phone-36px.svg';
import RayloPayTech from './svgs/raylo_pay/RP_tech.svg';
import RayloPayUpgrade from './svgs/raylo_pay/RP_upgrade.svg';
import RayloPayWallet from './svgs/raylo_pay/RP_wallet.svg';
import EcoGlobe from './svgs/icon/icon-custom-ecology-globe-nature.svg';
import RayloPayUpgradeArrow from './svgs/icon-streamline-upgrade.svg';
import RayloPayMonthlyWallet from './svgs/icon-custom-wallet.svg';
import RayloPayCardCheck from './svgs/icon-streamline-credit-card-check.svg';
import Trustpilot2 from './svgs/trustpilot.svg';
import ItemShipment from './svgs/icon-streamline-shipment-upload.svg';
import ItemWarranty from './svgs/icon-streamline-warranty.svg';
import PoweredByRayloPayLogo from './svgs/logo_powered-by-raylo-pay.svg';
import Form from './svgs/form.svg';
import PreApproved from './svgs/pre-approved.svg';
import Info from './svgs/icon-custom-info.svg';
import Alert from './svgs/alert.svg';
import Devices from './svgs/icon/streamline/devices.svg';
import RedAlert from './svgs/red-alert.svg';

export const icons = [
  {
    iconName: 'RedAlert',
    icon: <RedAlert />,
  },
  {
    iconName: 'Form',
    icon: <Form />,
  },
  {
    iconName: 'Logo',
    icon: <Logo />,
  },
  {
    iconName: 'Twitter',
    icon: <Twitter />,
  },
  {
    iconName: 'Instagram',
    icon: <Instagram />,
  },
  {
    iconName: 'Facebook',
    icon: <Facebook />,
  },
  {
    iconName: 'Warranty',
    icon: <Warranty />,
  },
  {
    iconName: 'PhoneSelfie',
    icon: <PhoneSelfie />,
  },
  {
    iconName: 'Calendar',
    icon: <Calendar />,
  },
  {
    iconName: 'CertifiedPhoneShield',
    icon: <CertifiedPhoneShield />,
  },
  {
    iconName: 'CertifiedWarranty',
    icon: <CertifiedWarranty />,
  },
  {
    iconName: 'PhoneDoubleCamera',
    icon: <PhoneDoubleCamera />,
  },
  {
    iconName: 'CustomerSupport36px',
    icon: <CustomerSupport36px />,
  },
  {
    iconName: 'PhoneDoubleCamera36px',
    icon: <PhoneDoubleCamera36px />,
  },
  {
    iconName: 'EcologyGlobe36px',
    icon: <EcologyGlobe36px />,
  },
  {
    iconName: 'CertifiedPhoneShield36px',
    icon: <CertifiedPhoneShield36px />,
  },
  {
    iconName: 'RayloPayTech',
    icon: <RayloPayTech />,
  },
  {
    iconName: 'RayloPayUpgrade',
    icon: <RayloPayUpgrade />,
  },
  {
    iconName: 'RayloPayWallet',
    icon: <RayloPayWallet />,
  },
  {
    iconName: 'EcoGlobe',
    icon: <EcoGlobe />,
  },
  {
    iconName: 'RayloPayUpgradeArrow',
    icon: <RayloPayUpgradeArrow />,
  },
  {
    iconName: 'RayloPayMonthlyWallet',
    icon: <RayloPayMonthlyWallet />,
  },
  {
    iconName: 'RayloPayCardCheck',
    icon: <RayloPayCardCheck />,
  },
  {
    iconName: 'Trustpilot2',
    icon: <Trustpilot2 />,
  },
  {
    iconName: 'ItemShipment',
    icon: <ItemShipment />,
  },
  {
    iconName: 'ItemWarranty',
    icon: <ItemWarranty />,
  },
  {
    iconName: 'PoweredByRayloPayLogo',
    icon: <PoweredByRayloPayLogo />,
  },
  {
    iconName: 'PreApproved',
    icon: <PreApproved />,
  },
  {
    iconName: 'Info',
    icon: <Info />,
  },
  {
    iconName: 'Alert',
    icon: <Alert />,
  },

  {
    iconName: 'Devices',
    icon: <Devices />,
  },
] as const;
