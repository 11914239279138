import { Container, Typography } from 'uibook';
import { px2Rem } from '../../utils/px2Rem';
import { IIneligiblePaymentBox } from './types';
import { StyledIneligiblePaymentBox } from './styles';

const IneligiblePaymentBox = ({
  dataTestId,
  label,
  formattedMonthlyPrice,
}: IIneligiblePaymentBox) => {
  return (
    <StyledIneligiblePaymentBox data-testid={`${dataTestId}-ineligible`}>
      <Container flexRow width="100%" padding={`0 ${px2Rem(24)}`}>
        <Container padding={`0 ${px2Rem(8)} 0 0`} centerVertical width="fit-content">
          <Container>
            <Typography variant="body2" className="whitespace-nowrap">
              {label}
            </Typography>
          </Container>
          <Container padding={`${px2Rem(8)} 0 0 0`}>
            <Typography variant="fineprint">Exceeds limit</Typography>
          </Container>
        </Container>
        <Container alignRight noPadding width="fit-content" margin="0 0 0 auto" centerVertical>
          <Container flexRow alignRight centerVertical>
            <Typography variant="h4" element="p" bold>
              {formattedMonthlyPrice}
            </Typography>
            <Typography variant="body2">/mo</Typography>
          </Container>
        </Container>
      </Container>
    </StyledIneligiblePaymentBox>
  );
};

export default IneligiblePaymentBox;
