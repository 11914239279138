/**
 * Capitalizes the first letter of a string and converts the rest of the string to lowercase. If the
 * input string is empty or falsy, an empty string is returned.
 *
 * @param inputString - The string to capitalize.
 * @returns The capitalized string.
 */
export const capitalize = (inputString: string): string => {
  if (!inputString) {
    return '';
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
};

/**
 * Converts a string to start case.
 *
 * @param inputString - The input string to convert.
 * @returns The converted string in start case.
 */
export const startCase = (inputString: string): string => {
  if (!inputString) {
    return '';
  }

  return inputString
    .split(' ')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

/**
 * Removes specified characters from the beginning and end of a string.
 *
 * @param inputString - The string to trim.
 * @param chars - The characters to remove from the string.
 * @returns The trimmed string.
 */
export const trimChars = (inputString: string, chars: string): string => {
  if (!inputString) {
    return '';
  }
  if (!chars) {
    return inputString;
  }
  return inputString.replace(new RegExp(`^${chars}+|${chars}+$`, 'g'), '');
};

/**
 * Converts a string to camel case.
 *
 * @param inputString - The input string to convert.
 * @returns The converted string in camel case.
 */
export const camelCase = (inputString: string): string => {
  const arr = inputString.match(/[a-z]+|\d+/gi);
  if (!arr) {
    return '';
  }
  return arr
    .map((m, i) => {
      let low = m.toLowerCase();
      if (i !== 0) {
        low = low
          .split('')
          .map((s, k) => (k === 0 ? s.toUpperCase() : s))
          .join('');
      }
      return low;
    })
    .join('');
};

const stripEmojiRegexp =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

/**
 * Converts a markdown string into an array of strings, with each element representing a line of the
 * markdown.
 *
 * This function performs the following transformations:
 *
 * - Removes any emojis from the markdown string.
 * - Removes any leading hyphens and spaces from each line.
 * - Splits the markdown string into an array of lines.
 * - Filters out any empty lines.
 *
 * @param markdown - The markdown string to be converted. If not provided, the function returns
 *   `undefined`.
 * @returns An array of strings representing the lines of the markdown, or `undefined` if the input
 *   is not provided.
 */
export const formatMarkdownToArrayOrUndefined = (markdown?: string | null) => {
  return markdown
    ?.replace(stripEmojiRegexp, '')
    .replace(/^[ *-]+/gm, '')
    .replace(/ +$/gm, '')
    .split(/\n/)
    .filter((n) => n);
};
