import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { getAlternateConsumerUrl } from '@/utils/urls.utils';
import { Button, ConsumerTypesEnum, RayloModal, Typography } from 'uibook';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { SHOW_SMB_MODAL } from '@/constants/features';
import { useCustomerContext } from '@/hooks/useCustomerContext';

const SMB_KEY = 'hasSeenSmbInfoModal';

const SmbInfoModalInner = () => {
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { consumerType } = useConsumerTypeContext();

  /** Check for the storage item in the users browser when the component is mounted. */
  useEffect(() => {
    if (!isModalOpen && !localStorage.getItem(SMB_KEY)) {
      setIsModalOpen(true);
    }
  }, [isModalOpen]);

  /** Wrapper for updating the browser storage */
  const setStorageInBrowser = () => {
    localStorage.setItem(SMB_KEY, 'true');
  };

  /**
   * When the user clicks on the CTA, set the storage and redirect them to the appropriate consumer
   * type, if needed, then close the modal.
   */
  const handleClickCta = (event: React.MouseEvent, targetConsumerType: ConsumerTypesEnum) => {
    event.preventDefault();

    setStorageInBrowser();

    if (consumerType !== targetConsumerType) {
      router.push(getAlternateConsumerUrl(consumerType, router.asPath));
    }

    setIsModalOpen(false);
  };

  /**
   * When the modal is closed via the `X` or by clicking the overlay, update the storage and close
   * the modal.
   */
  const handleCloseModal = () => {
    setStorageInBrowser();
    setIsModalOpen(false);
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <RayloModal
      modalOpen
      title="Are you here for Personal or Business tech?"
      dataTestId="modal-wrapper"
      handleCloseModal={handleCloseModal}
    >
      <Typography className="mb-9">
        Please select what you’re here for and we’ll direct you to the right place.
      </Typography>

      <div className="flex flex-col gap-3">
        <Button
          onClick={(event: React.MouseEvent) => handleClickCta(event, ConsumerTypesEnum.PERSONAL)}
          data-testid="smb-modal-cta-personal"
          size="large"
        >
          Personal
        </Button>
        <Button
          onClick={(event: React.MouseEvent) => handleClickCta(event, ConsumerTypesEnum.BUSINESS)}
          data-testid="smb-modal-cta-business"
          variant="outlined"
          size="large"
        >
          Business
        </Button>
      </div>
    </RayloModal>
  );
};

/**
 * Thin wrapper around `<SmbInfoModalInner />`, which can be removed if the experiment is a success.
 * This is only really used to have the `useGrowthBook` check close to the component.
 *
 * Also, the modal will only show if the user is not logged in, and the customer data is ready. This
 * also means that the modal won't show for users in the mobile app.
 *
 * If you find the modal quite annoying, you can disable locally by setting
 * `NEXT_PUBLIC_DEV_HIDE_SMB_MODAL` in your `.env.development.local` to a truthy value.
 */
export const SmbInfoModal = () => {
  const { isCustomerDataReady, hasLoggedInCustomer } = useCustomerContext();

  const featureShowSmbModal = useFeatureIsOn(SHOW_SMB_MODAL);

  const canShowSmbModal =
    featureShowSmbModal &&
    isCustomerDataReady &&
    !hasLoggedInCustomer &&
    !process.env.NEXT_PUBLIC_DEV_HIDE_SMB_MODAL;

  if (!canShowSmbModal) {
    return null;
  }

  return <SmbInfoModalInner />;
};
