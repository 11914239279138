import Container from '../../elements/Container';
import Divider from '../../elements/Divider';
import Icon from '../../elements/Icon';
import Spacer from '../../elements/Spacer';
import { useAppContext } from '../../hooks/useAppContext';
import { px2Rem } from '../../utils/px2Rem';
import {
  HeaderWrapper,
  StyledFooter,
  StyledFooterBaselineContainer,
  StyledFooterCopyrightContainer,
  StyledFooterMainContainer,
  StyledFooterOuterContainer,
  StyledFooterSocialContainer,
  StyledLink,
  StyledLinkWrapper,
} from './styles';
import type { IFooter } from './types';
import { footerSocialMediaLinks, getFooterLists, trustPilotFooterLogo } from './footerConstants';
import { Link } from '../../elements/Link/Link';
import { palette, Typography } from 'uibook';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { SHOW_ONLY_PHONES } from '@/constants/features';

export const Footer = ({ dataTestId }: IFooter) => {
  const { isRayloPay } = useAppContext();
  const { consumerIsBusiness } = useConsumerTypeContext();
  const showOnlyPhones: boolean = useFeatureIsOn(SHOW_ONLY_PHONES);

  const { footerListOne, footerListTwo } = getFooterLists({
    isRayloPay,
    consumerIsBusiness,
    showOnlyPhones,
  });

  const { title: listOneTitle, links: listOneLinks } = footerListOne;
  const { title: listTwoTitle, links: listTwoLinks } = footerListTwo;

  return (
    <StyledFooter data-testid={dataTestId}>
      <StyledFooterOuterContainer>
        <StyledFooterMainContainer>
          <Container flexRow>
            <Container>
              <HeaderWrapper>
                <Typography variant="body2" className="text-white">
                  {listOneTitle}
                </Typography>
              </HeaderWrapper>
              <Spacer height={24} />
              {listOneLinks.map(({ label, link }) => (
                <div key={link}>
                  {isRayloPay ? (
                    <StyledLinkWrapper>
                      <Link href={link} passHref>
                        {label}
                      </Link>
                    </StyledLinkWrapper>
                  ) : (
                    <StyledLink href={link}>{label}</StyledLink>
                  )}
                  <Spacer height={12} />
                </div>
              ))}
            </Container>
            <Container>
              <HeaderWrapper>
                <Typography variant="body2" className="text-white">
                  {listTwoTitle}
                </Typography>
              </HeaderWrapper>
              <Spacer height={24} />
              {listTwoLinks.map(({ label, link }) => (
                <div key={link}>
                  <StyledLink href={link} className="launch-intercom">
                    {label}
                  </StyledLink>
                  <Spacer height={12} />
                </div>
              ))}
            </Container>
          </Container>

          <Container>
            <Container maxWidth={px2Rem(263)}>
              <Spacer height={64} />
              {isRayloPay && <Icon name="PoweredByRayloPayLogo" dataTestId="raylo-pay-logo" />}
              {!isRayloPay && <Icon name="Logo" />}
              <Spacer height={32} />
              <Typography variant="fineprint" className="text-charcoal-300">
                Raylo Group Limited is authorised and regulated by the Financial Conduct Authority
                (841488).
              </Typography>
              <Spacer height={16} />
              <Typography variant="fineprint" className="text-charcoal-300">
                Raylo is a trading name of Raylo Group Limited. Raylo Group Limited is registered in
                England and Wales company number 11554120.
              </Typography>
            </Container>
            <Spacer height={24} />
            {!isRayloPay && (
              <Container>
                <Typography variant="fineprint" className="text-charcoal-300" bold>
                  Head Office
                </Typography>
                <Spacer height={4} />
                <Typography variant="fineprint" className="text-charcoal-300">
                  Head 5 New Street Square, City of London, EC4A 3TW
                </Typography>
              </Container>
            )}
          </Container>
        </StyledFooterMainContainer>

        <Container maxWidth={px2Rem(1200)}>
          <Divider width="100%" color={palette.charcoal[400]} opacity="1" padding={0} />
          <Spacer height={12} />
          <StyledFooterBaselineContainer>
            {!isRayloPay && (
              <StyledFooterSocialContainer>
                {footerSocialMediaLinks.map(({ icon, link }) => (
                  <a
                    key={link}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    title={`Raylo on ${icon}`}
                  >
                    <Icon name={icon} />
                  </a>
                ))}
              </StyledFooterSocialContainer>
            )}

            {isRayloPay && (
              <Container width="fit-content" flexRow>
                <Container center margin="-4px 0 0 0">
                  <Typography className="text-white">Excellent</Typography>
                </Container>
                <Container margin="0 0 0 8px">
                  <img src={trustPilotFooterLogo} alt="Trustpilot logo" height={24} />
                </Container>
                <Container margin="0 0 0 8px">
                  <Icon name="Trustpilot2" />
                </Container>
              </Container>
            )}

            <StyledFooterCopyrightContainer>
              <Typography variant="fineprint" className="text-charcoal-300">
                © Copyright of Raylo {new Date().getFullYear()}. All rights reserved.
              </Typography>
            </StyledFooterCopyrightContainer>
          </StyledFooterBaselineContainer>
        </Container>
      </StyledFooterOuterContainer>
    </StyledFooter>
  );
};
