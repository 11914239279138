import type { IBenefitsListItems } from './types';

export const getBenefitsInfoContent = (isRayloPay: boolean): IBenefitsListItems[] => {
  if (isRayloPay) {
    return [
      {
        icon: 'CustomerSupport36px',
        title: 'Wow Service',
        copy: 'Our UK based team works super hard. Check our reviews.',
      },
      {
        icon: 'ItemWarranty',
        title: 'Lifetime warranty',
        copy: 'Every Raylo Pay device comes with a lifetime warranty whilst you’re on a Raylo Pay lease.',
      },
      {
        icon: 'ItemShipment',
        title: 'Free next day delivery',
        copy: 'Choose your device and it could be in your hand the very next day',
      },
      {
        icon: 'CertifiedPhoneShield36px',
        title: 'Peace of mind',
        copy: 'Great insurance cover from just £5.99/month. Underwritten by Collinson, made simple by Raylo Pay.',
      },
    ];
  }

  return [
    {
      icon: 'CustomerSupport36px',
      title: 'Wow Service',
      copy: 'Our UK based team works super hard. Check our reviews.',
    },
    {
      icon: 'PhoneDoubleCamera36px',
      title: 'Top notch accessories',
      copy: 'Free case with screen protection that you’ll actually want to use.',
    },
    {
      icon: 'EcologyGlobe36px',
      title: 'Greener too',
      copy: 'Our refurb and reuse approach means that all Raylo tech gets as many lives as possible.',
    },
    {
      icon: 'CertifiedPhoneShield36px',
      title: 'Peace of mind',
      copy: 'Great insurance cover from just £5.99/month. Underwritten by Collinson, made simple by Raylo.',
    },
  ];
};
