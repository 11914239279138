import Icon from '../../elements/Icon';
import { px2Rem } from '../../utils/px2Rem';
import { palette, Typography } from 'uibook';

const PreApprovedAmountBanner = ({
  formattedAmount,
  shortVersion = false,
}: {
  formattedAmount: string;
  shortVersion?: boolean;
}) => (
  <div className="flex items-center" data-testid="pre-approved-amount-banner">
    <Icon
      name="PreApproved"
      size={px2Rem(24)}
      fill={palette.blue[400]}
      dataTestId="pre-approved-amount-banner-icon"
    />
    <Typography className="ml-2" variant="body2">
      {shortVersion ? 'Pre-approved' : 'Available pre-approved limit'}:&nbsp;
    </Typography>
    <Typography bold>{formattedAmount}/mo</Typography>
  </div>
);

export default PreApprovedAmountBanner;
