import Icon from '@/elements/Icon';
import { HeaderBanner } from '../HeaderBanner/HeaderBanner';
import { px2Rem } from '@/utils/px2Rem';
import { Typography } from 'uibook';
import { useCustomerContext } from '@/hooks/useCustomerContext';

export const InArrearsBanner = () => {
  const { itemInArrearsPaymentSlug } = useCustomerContext();

  if (!itemInArrearsPaymentSlug) return null;

  return (
    <HeaderBanner
      onClick={() => window.location.assign(itemInArrearsPaymentSlug)}
      className="px-4 py-2.5"
    >
      <div className="flex items-center">
        <Icon name="RedAlert" size={px2Rem(20)} />
        <Typography className="ml-2" variant="body2">
          Payments overdue.&nbsp;
        </Typography>
        <Typography
          className="text-blue-500 hover:underline"
          href={itemInArrearsPaymentSlug}
          element="a"
          variant="body2"
          title="Make overdue payment"
        >
          Pay now
        </Typography>
      </div>
    </HeaderBanner>
  );
};
